import React from 'react';

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  address: string;
};

const LinkTo: React.SFC<Props> = ({ address, children, ...props }) => (
  <a target="_blank" rel="noreferrer noopener" {...props} href={address}>
    {children}
  </a>
);

export default LinkTo;
