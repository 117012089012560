import React from 'react';
import useElementSize from '../useElementSize';
import { useSlider } from './Slider';
import Swipe from './Swipe';

type SliderProps = {
  translateX: string;
};

const Slider: React.FC<SliderProps> = ({ translateX, children }) => (
  <ul
    style={{
      display: 'flex',
      flexWrap: 'nowrap',
      transform: `translateX(-${translateX})`,
    }}
  >
    {children}
  </ul>
);

type SlideProps = {
  size: string;
  onSwipeLeft: () => void;
  onSwipeRight: () => void;
};

const Slide: React.FC<SlideProps> = ({
  size,
  onSwipeLeft,
  onSwipeRight,
  children,
}) => (
  <li style={{ flexBasis: size, flexShrink: 0 }}>
    <Swipe onSwipeLeft={onSwipeLeft} onSwipeRight={onSwipeRight}>
      {children}
    </Swipe>
  </li>
);

type SlidesProps = {
  mode?: 'fluid' | 'fixed';
  size?: number;
};

const Slides: React.FC<SlidesProps> = ({
  mode = 'fluid',
  size = 100,
  children,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [state, dispatch] = useSlider();
  const elementSize = useElementSize(ref);
  const unit = mode === 'fixed' ? 'px' : '%';
  const nbMirrorSlide =
    // TODO: fluid mode
    mode === 'fixed' ? Math.floor(elementSize / size) : 0;

  const slideProps = {
    size: `${size}${unit}`,
    onSwipeLeft: () => dispatch({ type: 'PREVIOUS' }),
    onSwipeRight: () => dispatch({ type: 'NEXT' }),
  };

  React.useEffect(() => {
    dispatch({
      type: 'SLIDES',
      slides: React.Children.toArray(children),
    });
  }, [children]);

  return (
    <div ref={ref} style={{ width: '100%', overflow: 'hidden' }}>
      <Slider translateX={`${(state.activeSlide + 1) * size}${unit}`}>
        <Slide {...slideProps}>
          {React.Children.toArray(children)[React.Children.count(children) - 1]}
        </Slide>
        {React.Children.map(children, (slide) => (
          <Slide {...slideProps}>{slide}</Slide>
        ))}
        {React.Children.toArray(children)
          .slice(0, Math.max(nbMirrorSlide, 1))
          .map((slide, idx) => (
            <Slide key={idx} {...slideProps}>
              {slide}
            </Slide>
          ))}
      </Slider>
    </div>
  );
};

export default Slides;
