import React from 'react';

type Props = React.SVGAttributes<HTMLOrSVGElement>;

const Moon: React.FC<Props> = (props) => (
  <svg
    height="18"
    viewBox="0 0 17 18"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m585.969595 97.0017795c-.04307.0032169-.085451.0126893-.125823.0281222-3.425557 1.3864258-5.843772 4.7343653-5.843772 8.6405383 0 5.147942 4.200023 9.32956 9.366812 9.32956 3.089404 0 5.835698-1.495787 7.54237-3.796501.121568-.161354.12104-.384494-.001289-.545266-.12233-.160771-.336342-.219589-.522962-.143728-.985892.398998-2.064188.625708-3.194513.625708-4.688462 0-8.472072-3.773395-8.472072-8.429622 0-1.872107.612002-3.5928952 1.649677-4.9916948.105402-.1422596.11786-.3335294.031812-.4884037-.086047-.1548743-.254539-.2444434-.43024-.228713zm-1.127001.9982205c-.607785 1.2831109-.957216 2.718429-.957216 4.244908 0 5.313183 4.137683 9.629004 9.227735 9.629004.647142 0 1.277206-.084613 1.886887-.217687-1.488469 1.438963-3.459315 2.343775-5.65372 2.343775-4.618892 0-8.34628-3.894542-8.34628-8.700235 0-3.071343 1.536707-5.752922 3.842594-7.299765z"
      fill="#fbc5a9"
      opacity=".509"
      transform="translate(-580 -97)"
    />
  </svg>
);

export default Moon;
