import React from 'react';

type Props = React.SVGAttributes<HTMLOrSVGElement>;

const Background: React.FC<Props> = (props) => (
  <svg
    height="650"
    viewBox="0 0 1756 650"
    width="1756"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m669.491396 4738.43821c-38.729436 59.41239-285.893896 183.69-314.406401 164.45657-18.304467-12.34748-12.45476-342.01681-32.920559-551.35651-11.668297-119.35217 38.455817-207.85955 34.309395-218.38759-22.128491-56.18568-41.870138-118.03267-34.870531-179.98211 3.43145-30.36976 15.327627-41.65271 17.32131-44.84843 22.525609-36.10686 45.668253-71.86009 70.711525-106.07858 16.205959-22.14342 34.660686-42.3799 52.846168-62.74755 79.346967-88.86821 156.296535-160.42824 260.44456-214.44875 49.474376-25.66185 111.661983-37.35445 165.015615-46.57486 44.963907-7.77052 104.421818 21.69624 141.606852 33.37504 14.31496 4.49593 37.53311 12.09274 51.15521 12.09274 77.95616 0 144.88099-41.23188 216.2664-67.64039 27.16875-10.05087 90.47849-39.61982 115.61753-19.73393 21.61982 17.10207 86.28671 104.28846 96.52951 128.45002 20.20463 47.66034-9.33066 220.27472-10.36321 269.16326-1.09978 52.0724 23.05529 152.39834-6.99047 182.66416-32.69452 32.93397-73.65329 60.81529-112.70572 85.42706-66.97848 42.21143-116.94297 196.06513-240.3765 211.18972-39.59053 4.85111-355.263855 250.21214-469.190684 424.98013z"
      fill="#fef6f2"
      fillRule="evenodd"
      opacity=".544"
      transform="matrix(-.76604444 -.64278761 .64278761 -.76604444 -1124.204292 4064.9431)"
    />
  </svg>
);

export default Background;
