import React from 'react';
import { Link } from 'gatsby';
import Breadcrumb, { Crumb } from '../Breadcrumb/Breadcrumb';
import Section from '../Section/Section';
import Title from '../Section/Title';
import HLShape from '../Icon/Artworks/Header/LShape';
import HRShape from '../Icon/Artworks/Header/RShape';
import HHeart from '../Icon/Artworks/Header/Heart';
import HMoon from '../Icon/Artworks/Header/Moon';
import FBackground from '../Icon/Artworks/Footer/Background';
import FMShape from '../Icon/Artworks/Footer/MShape';
import FRShape from '../Icon/Artworks/Footer/RShape';
import FFlower from '../Icon/Artworks/Footer/Flower';
import Banner from '../Banner';
import Mailto from '../Mailto';
import Logo from './Logo';
import Header from './Header';
import Looper from './Looper';
import FAQ from './FAQ';
import Instagam from './Instagram';
import Footer from './Footer';
import useMenu from './useMenu';

type Props = {
  title: React.ReactNode;
  crumbs?: Crumb[];
};

const Layout: React.SFC<Props> = ({ title, children, crumbs = [] }) => {
  const className = '--use-menu-header-sentinel';
  const [props, setState] = useMenu(className);

  return (
    <React.Fragment>
      <div className="overflow-hidden">
        <Banner>
          Merci pour vos messages ✨ De nouvelles poupées seront disponibles
          pour la rentrée 2024 🎄 Pour toute demande :{' '}
          <Mailto
            className="current"
            address="hello@poupeeodette.fr"
            subject="Hello !"
          >
            hello@poupeeodette.fr
          </Mailto>
        </Banner>
        <Header
          className={className}
          menuHTMLProps={props.menu}
          onMenuClick={setState}
        />
        <main
          {...props.main}
          className="relative md:mrb-0:important md:transform-none:important md:transition-none:important"
        >
          <div className="relative mw-1080x mrr-auto mrl-auto">
            <HLShape className="absolute awh-tl-ls" />
            <HHeart className="absolute awh-tl-lh" />
            <HMoon className="absolute awh-tl-lm" />
            <HRShape className="absolute awh-tl-rs" />
          </div>
          {Boolean(crumbs.length) && <Breadcrumb>{crumbs}</Breadcrumb>}
          <h1 className="ta-center">
            <Link to="/">
              <Logo className="w-150x pdb-50x" />
            </Link>
          </h1>
          <Title className="mrb-75x">{title}</Title>
          {children}
        </main>
        <Looper className="mrb-85x">
          Poupée chiffon dessinée et cousue à la main à Paris. Poupée chiffon
          dessinée et cousue à la main à Paris.
        </Looper>
        <Section>
          <FAQ />
        </Section>
        <div className="relative mw-1080x mrr-auto mrl-auto">
          <FBackground className="absolute awf-mm-bg" />
          <FMShape className="absolute awf-mm-ms" />
          <FRShape className="absolute awf-mr-rs" />
          <FFlower className="absolute awf-br-fw" />
        </div>
        <Section>
          <Instagam />
        </Section>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Layout;
