import React from 'react';
import cx from 'classnames';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> &
  React.LinkHTMLAttributes<HTMLLinkElement> & {
    component?: React.ElementType;
    to?: string;
  };

const Button: React.FC<Props> = ({
  component: Component = 'button',
  className,
  children,
  ...props
}) => (
  <Component {...props} className={cx('btn', className)}>
    {children}
  </Component>
);

export default Button;
