import React from 'react';
import cx from 'classnames';
import Mailto from '../../components/Mailto';
import LinkTo from '../../components/LinkTo';
import ArtworkFAQMoon from '../../images/artworks/faq/moon.inline.svg';
import ArtworkFAQSun from '../../images/artworks/faq/sun.inline.svg';
import * as s from './FAQ.module.css';

const FAQ = () => (
  <React.Fragment>
    <ArtworkFAQMoon className={s.ArtworkMoon} />
    <ArtworkFAQSun className={s.ArtworkSun} />
    <ul className={s.List}>
      <li className={cx(s.ListItem, s.ListItemShippingFrance)}>
        Livraison en{' '}
        <b>
          France <br /> métropolitaine
        </b>
      </li>
      <li className={cx(s.ListItem, s.ListItemPayment)}>
        Paiement <b>sécurisé</b>
      </li>
      <li className={cx(s.ListItem, s.ListItemMessage)}>
        Une question ?<br />
        <Mailto
          className={s.ListItemMessageLink}
          address="hello@poupeeodette.fr"
          subject="Besoin d’une réponse"
        >
          hello@poupeeodette.fr
        </Mailto>
      </li>
      <li className={cx(s.ListItem, s.ListItemWhatsapp)}>
        Besoin d’une
        <br />
        réponse rapide ? <br />
        <LinkTo
          className={s.ListItemMessageLink}
          address="https://api.whatsapp.com/send?phone=+33645627363‬"
        >
          Whatsapp
        </LinkTo>
      </li>
    </ul>
  </React.Fragment>
);

export default FAQ;
