import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Image, { FixedObject } from 'gatsby-image';
import LinkTo from '../../components/LinkTo';
import Slider from '../../components/Slider/Slider';
import Slides from '../../components/Slider/Slides';
import Control from '../../components/Control/Control';
import track from '../../modules/analytics/track';
import * as s from './instagram.module.css';

type FixedImageObject = {
  childImageSharp: {
    fixed: FixedObject;
  };
};

type Post = {
  id: string;
  link: string;
  createdAt: string;
  image: FixedImageObject;
};

type Queries = {
  posts: {
    nodes: Post[];
  };
};

const Instagram: React.FC = () => {
  const data = useStaticQuery<Queries>(graphql`
    query {
      posts: allInstagram(sort: { fields: [createdAt], order: DESC }) {
        nodes {
          id
          link
          createdAt
          image {
            childImageSharp {
              fixed(width: 200, height: 200, quality: 90) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className={s.Body}>
      <LinkTo
        className={s.Account}
        address="https://www.instagram.com/poupee.odette/"
      >
        @poupee.odette sur Instagram
      </LinkTo>
      <div>
        <Slider>
          <div className={s.Viewer}>
            <Slides mode="fixed" size={215}>
              {data.posts.nodes.map((post) => {
                const name =
                  post.createdAt + ' - Photo by Poupée Odette (@poupee.odette)';

                return (
                  <LinkTo
                    key={post.id}
                    className={s.Link}
                    address={post.link}
                    onClick={() =>
                      track({
                        category: 'instagram',
                        action: 'instagram_click',
                        label: post.link,
                      })
                    }
                  >
                    <Image
                      className={s.Image}
                      alt={name}
                      fixed={post.image.childImageSharp.fixed}
                    />
                  </LinkTo>
                );
              })}
            </Slides>
          </div>
          <Control className={s.Control} />
        </Slider>
      </div>
    </div>
  );
};

export default Instagram;
