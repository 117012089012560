import React from 'react';
import cx from 'classnames';
import { useSlider } from '../../components/Slider/Slider';
import Icon from '../../images/icons/control.inline.svg';

type Props = {
  className: string;
};

const Control: React.FC<Props> = ({ className }) => {
  const [state, dispatch] = useSlider();

  return (
    <ul className={cx('flex justify-center', className)}>
      {state.slides.map((_, idx) => (
        <li key={idx}>
          <button
            aria-label={`slide-${idx + 1}`}
            className="appearance-none bgc-transparent br-none transparent pdt-8x pdr-8x pdb-8x pdl-8x hover:cursor-pointer focus:outline-none focus:stroke-pink"
            onClick={() => dispatch({ type: 'PAGE', index: idx })}
          >
            <Icon
              className={cx(
                'block w-15x h-15x fill-current',
                idx === state.activeSlide && 'coral'
              )}
            />
          </button>
        </li>
      ))}
    </ul>
  );
};

export default Control;
