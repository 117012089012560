import { State, CartItem } from './definition';

const getCartItem = (state: State, id: string) => state.byId[id];

export const getCartItems = (state: State) =>
  state.ids.map((id) => getCartItem(state, id));

export const getTotalItem = (item: CartItem) =>
  item.quantity * item.product.variant.price;

export const getTotalItems = (state: State) =>
  state.ids.reduce((acc, id) => acc + getTotalItem(getCartItem(state, id)), 0);

export const getQuantityItems = (state: State) =>
  state.ids.reduce((acc, id) => acc + getCartItem(state, id).quantity, 0);

export const getShippingItems = (state: State) =>
  state.ids.reduce(
    (acc, id) => Math.max(acc, getCartItem(state, id).product.variant.shipping),
    0
  );

export const getTotalCoupon = (state: State, total: number) =>
  Math.max(0, total - getAmountCoupon(state, total));

export const getAmountCoupon = (state: State, total: number) => {
  if (state.coupon?.amountOff) {
    return state.coupon.amountOff;
  }

  if (state.coupon?.percentOff) {
    return (total * state.coupon.percentOff) / 100;
  }

  return 0;
};
