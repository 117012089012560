import React from 'react';
import { Link } from 'gatsby';
import Mailto from '../Mailto';
import PopHeart from '../../images/icons/pop-heart.inline.svg';
import * as s from './footer.module.css';

const Footer = () => (
  <footer className={s.Footer}>
    <p className={s.Address}>
      <PopHeart className={s.AddressHeart} />
      <Mailto
        address="hello@poupeeodette.fr"
        subject="Hello"
        className={s.AddressLink}
      >
        hello@poupeeodette.fr
      </Mailto>
    </p>
    <p className={s.Copyrights}>
      © Poupée Odette | {new Date().getFullYear()} | Tous droits réservés |{' '}
      <Link to="/faq/">FAQ</Link> |{' '}
      <Link to="/mentions-legales/">Mentions légales</Link> |{' '}
      <Link to="/conditions-generales/">CGV</Link>
    </p>
  </footer>
);

export default Footer;
