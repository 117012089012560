import React from 'react';

type Props = {
  onSwipeLeft: () => void;
  onSwipeRight: () => void;
};

type Point = {
  x: number;
  y: number;
};

const SWIPE_THRESHOLD = 50;

const getPoint = (event: TouchEvent): Point | null => {
  if (!event.targetTouches || !event.targetTouches[0]) {
    return null;
  }

  return {
    x: event.targetTouches[0].clientX,
    y: event.targetTouches[0].clientY,
  };
};

const Swipe: React.FC<Props> = ({ onSwipeLeft, onSwipeRight, children }) => {
  const el = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    let gesture: 'horizontal' | 'vertical' | null = null;
    let start = { x: 0, y: 0 };
    let end = { x: 0, y: 0 };

    const onTouchStart = (event: TouchEvent) => {
      const point = getPoint(event);
      if (point) {
        start = point;
        end = point;
      }
    };

    const onTouchMove = (event: TouchEvent) => {
      const point = getPoint(event);
      if (point) {
        end = point;
      }

      const dx = Math.abs(start.x - end.x);
      const dy = Math.abs(start.y - end.y);

      if (gesture === null) {
        // Once one of the gesture is dectected we stick with it.
        gesture = dx > dy ? 'horizontal' : 'vertical';
      }

      if (event.cancelable && gesture === 'horizontal') {
        // Avoid `preventDefault` an event which is not a `cancelable`
        // otherwise Chrome logs intervention errors in the console.
        event.preventDefault();
      }
    };

    const onTouchEnd = () => {
      const swipe = gesture === 'horizontal';
      const dx = start.x - end.x;

      gesture = null;
      start = { x: 0, y: 0 };
      end = { x: 0, y: 0 };

      if (!swipe) {
        return;
      }

      if (Math.abs(dx) < SWIPE_THRESHOLD) {
        return;
      }

      if (dx < 0) {
        onSwipeLeft();
      } else {
        onSwipeRight();
      }
    };

    el.current!.addEventListener('touchstart', onTouchStart);
    el.current!.addEventListener('touchmove', onTouchMove);
    el.current!.addEventListener('touchend', onTouchEnd);

    return () => {
      if (!el.current) {
        return;
      }

      el.current.removeEventListener('touchstart', onTouchStart);
      el.current.removeEventListener('touchmove', onTouchMove);
      el.current.removeEventListener('touchend', onTouchEnd);
    };
  }, []);

  return <div ref={el}>{children}</div>;
};

export default Swipe;
