import React from 'react';

type Props = React.SVGAttributes<HTMLOrSVGElement>;

const Heart: React.FC<Props> = (props) => (
  <svg
    height="13"
    viewBox="0 0 18 13"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      fill="#ffc633"
      fillRule="evenodd"
      transform="matrix(.91354546 -.40673664 .40673664 .91354546 -.41583 6.339676)"
    >
      <path d="m18.2026302 3.26537072c-.2525354 1.18851875-1.0204852 2.07346825-1.800788 2.96504602-1.0840118 1.23864946-2.3745572 2.25678076-3.789545 3.14917325-.4136475.26071114-.7689899.60099349-1.1565306.89836711-1.1855381.9103164-2.38761057.5768234-3.59345398.0399214-.19986195-.0893479-.41770857-.1439343-.61612014-.2357263-.14909876-.0689798-.4020445-.18494197-.39334224-.25772383.01827475-.1583277.13633544-.36119355.28050291-.44293736 1.6705442-.95023777-1.4866364-2.48798402.11283933-3.53191485 1.54001027-1.00509575 4.78494142-2.83974207 6.12073862-4.07133061.6622421-.61104173 5.4556443-1.43055032 4.8356991 1.48712517z" />
      <path
        d="m11.4897416 3.10468554c-.2195871 1.19383974-1.0204852 2.07346825-1.80078805 2.96504602-1.08401175 1.23864947-2.37455718 2.25678077-3.78954496 3.14917326-.41364751.26071114-.76898987.60099348-1.1565306.89836708-1.18553813.9103164-2.38761057.5768234-3.59345398.0399214-.19986195-.0893478-.41770857-.1439342-.61612014-.23572628-.14909875-.06897983-.4020445-.18494197-.39334224-.25772382.01827475-.1583277.13633544-.36119356.28050291-.44293736 1.11369613-.63349185 1.6180978-2.15850964 1.51320501-4.57505337 1.93841469-2.62351812 3.51046025-4.09148483 4.71613668-4.40390014 4.08470027-1.05842897 4.96495787 2.18335482 4.83993537 2.86283321z"
        transform="matrix(-1 0 0 1 11.64 0)"
      />
    </g>
  </svg>
);

export default Heart;
