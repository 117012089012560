import React from 'react';

type Context = [State, React.Dispatch<Action>];

type Slide = React.ReactNode;

type State = {
  slides: Slide[];
  activeSlide: number;
};

type Action =
  | { type: 'NEXT' }
  | { type: 'PREVIOUS' }
  | { type: 'PAGE'; index: number }
  | { type: 'SLIDES'; slides: Slide[] };

type Reducer = React.Reducer<State, Action>;

const initialState: State = {
  slides: [],
  activeSlide: 0,
};

const reducer: Reducer = (prevState, action) => {
  switch (action.type) {
    case 'PREVIOUS': {
      const previous = prevState.activeSlide - 1;
      const nbSlides = prevState.slides.length;

      return {
        ...prevState,
        activeSlide: previous >= 0 ? previous : nbSlides - 1,
      };
    }

    case 'NEXT': {
      const next = prevState.activeSlide + 1;
      const nbSlides = prevState.slides.length;

      return {
        ...prevState,
        activeSlide: next < nbSlides ? next : 0,
      };
    }

    case 'PAGE': {
      return {
        ...prevState,
        activeSlide: action.index,
      };
    }

    case 'SLIDES': {
      return {
        ...prevState,
        slides: action.slides,
      };
    }

    default: {
      return prevState;
    }
  }
};

export const SliderContext = React.createContext<Context>([
  initialState,
  () => {},
]);

export const useSlider = () => React.useContext<Context>(SliderContext);

const Slider: React.FC = ({ children }) => {
  const contextValue = React.useReducer<Reducer>(reducer, initialState);

  return (
    <SliderContext.Provider value={contextValue}>
      {children}
    </SliderContext.Provider>
  );
};

export default Slider;
