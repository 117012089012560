import React from 'react';

type Setter = React.Dispatch<React.SetStateAction<boolean>>;

type State = {
  menu: React.HTMLAttributes<HTMLDivElement>;
  main: React.HTMLAttributes<HTMLDivElement>;
};

const useMenu = (sentinel: string): [State, Setter] => {
  const [isOpen, setIsOpen] = React.useState(false);

  const translateY = -175;
  const style = {
    // Disabled with CSS on large screen... We should have self-contained
    // logic but nevermind. We have to redo the menu anyway it doesn't work.
    transform: `translateY(${isOpen ? 0 : translateY}px)`,
    transition: 'transform 0.5s',
  };

  const state = {
    menu: {
      style,
    },
    main: {
      style: {
        ...style,
        // Disabled with CSS on large screen... We should have self-contained
        // logic but nevermind. We have to redo the menu anyway it doesn't work.
        marginBottom: `${translateY}px`,
      },
    },
  };

  React.useEffect(() => {
    if (!isOpen) {
      return;
    }

    const scrollable = document.querySelectorAll<HTMLElement>('html, body');
    const onClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.closest(`.${sentinel}`) === null) {
        setIsOpen(false);
      }
    };

    const onResize = () => {
      setIsOpen(false);
    };

    document.addEventListener('click', onClick);
    window.addEventListener('resize', onResize);
    scrollable.forEach((element) => {
      element.style.overflow = 'hidden';
    });

    return () => {
      document.removeEventListener('click', onClick);
      window.removeEventListener('resize', onResize);
      scrollable.forEach((element) => {
        element.style.overflow = '';
      });
    };
  }, [isOpen]);

  return [state, setIsOpen];
};

export default useMenu;
