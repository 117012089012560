import React from 'react';

const getWidth = (ref: React.RefObject<HTMLElement>): number =>
  ref && ref.current ? ref.current.getBoundingClientRect().width : 0;

const useElementSize = (ref: React.RefObject<HTMLElement>) => {
  const [state, setState] = React.useState(() => getWidth(ref));

  React.useEffect(() => {
    const listener = () => setState(getWidth(ref));

    window.addEventListener('resize', listener, {});

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  return state;
};

export default useElementSize;
