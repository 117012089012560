// extracted by mini-css-extract-plugin
export var Header = "header-module--Header--3lgJC";
export var Menu = "header-module--Menu--2dM_k";
export var Background = "header-module--Background--1kdp0";
export var Container = "header-module--Container--HkCVK";
export var Toggle = "header-module--Toggle--AbDnf";
export var ToggleBar = "header-module--ToggleBar--1G2Aw";
export var Link = "header-module--Link--1mNbD";
export var LinkActive = "header-module--LinkActive--1Xv2e";
export var Contact = "header-module--Contact--1LH-0";
export var Artworks = "header-module--Artworks--1Dvlg";
export var ArtworkTopRight = "header-module--ArtworkTopRight--MuyTS";
export var ArtworkLeft = "header-module--ArtworkLeft--13xQ1";