import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import Mailto from '../Mailto';
import Button from '../Button/Button';
import Cart from '../../modules/cart/Cart';
import { getQuantityItems } from '../../modules/cart/selector';
import * as s from './header.module.css';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  menuHTMLProps: React.HTMLAttributes<HTMLDivElement>;
  onMenuClick: React.Dispatch<React.SetStateAction<boolean>>;
};

const Header: React.FC<Props> = ({ className, menuHTMLProps, onMenuClick }) => {
  const [cart] = Cart.useCart();
  const quantity = getQuantityItems(cart);

  const toggle = () => onMenuClick((_) => !_);
  const close = () => onMenuClick(false);

  return (
    <header className={cx(s.Header, className)}>
      <button className={s.Toggle} onClick={toggle} aria-label="open/close">
        <span className={s.ToggleBar} />
        <span className={s.ToggleBar} />
        <span className={s.ToggleBar} />
      </button>

      <nav {...menuHTMLProps} className={s.Menu}>
        <div className={s.Background}>
          <div className={s.Container}>
            <Link
              to="/"
              className={s.Link}
              activeClassName={s.LinkActive}
              onClick={close}
            >
              Accueil
            </Link>
            <Link
              to="/shop/"
              className={s.Link}
              activeClassName={s.LinkActive}
              onClick={close}
            >
              Shop
            </Link>
            <Mailto
              address="hello@poupeeodette.fr"
              subject="Hello !"
              className={cx(s.Link, s.Contact)}
            >
              Contact
            </Mailto>
            <Button
              to="/shop/panier/"
              className="flex item-center"
              component={Link}
              onClick={close}
            >
              Panier
              {quantity !== 0 && (
                <span className="inline-block w-19x h-19x mrl-10x brr-1/2 bgc-yellow fw-bold ta-center fsz-80 lh-19x white">
                  {quantity}
                </span>
              )}
            </Button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
