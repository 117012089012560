import React from 'react';
import cx from 'classnames';

type Props = React.HTMLAttributes<HTMLHeadingElement>;

const Title: React.FC<Props> = ({ className, children, ...props }) => (
  <h1
    {...props}
    className={cx(
      'blue ff-lora fsz-xlg fw-regular ta-center mrr-auto mrb-50x mrl-auto',
      className
    )}
  >
    {children}
  </h1>
);

export default Title;
