import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

type Queries = {
  site: {
    siteMetadata: {
      siteURL: string;
    };
  };
};

type Props = {
  title: string;
  description?: string;
  keywords?: string[];
  robots?: string;
};

const SEO: React.SFC<Props> = ({
  title,
  description = 'Poupée Odette, notre poupée est 100% artisanale et fabriquée en France dans une popeline de coton toute douce et française certifiée GOTS, sans produits toxiques.',
  keywords = [
    'poupée',
    'poupée chiffon',
    'doll',
    'frenchdoll',
    'poupée éthique',
    'poupée française',
    'made in france',
    'fabriqué en France',
    'fabriqué à Paris',
    'oekotex',
    'gots',
    'poupée artisanale',
    'création française',
    'fabrication artisanale',
  ],
  robots = 'index,follow',
}) => {
  const data = useStaticQuery<Queries>(graphql`
    query {
      site {
        siteMetadata {
          siteURL: siteUrl
        }
      }
    }
  `);

  return (
    <Helmet
      htmlAttributes={{ lang: 'fr' }}
      title={title}
      meta={[
        {
          name: `robots`,
          content: robots,
        },
        {
          name: `description`,
          content: description,
        },
        {
          name: `keywords`,
          content: keywords.join(', '),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `${data.site.siteMetadata.siteURL}/images/og-image.jpg`,
        },
      ]}
    >
      <link
        href="https://fonts.googleapis.com/css?family=Lora:400,700,700i|Open+Sans:300,400,700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};

export default SEO;
