import React from 'react';
import cx from 'classnames';

type Props = React.HTMLAttributes<HTMLDivElement>;

const Section: React.FC<Props> = ({ className, children, ...props }) => (
  <div {...props} className={cx('relative overflow-hidden', className)}>
    <div className="relative mw-1080x mrr-auto mrl-auto pdb-85x z-110">
      {children}
    </div>
  </div>
);

export default Section;
