import React from 'react';

type Props = React.SVGAttributes<HTMLOrSVGElement>;

const RShape: React.FC<Props> = (props) => (
  <svg
    height="638"
    viewBox="0 0 480 638"
    width="480"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g
        fill="#ffc633"
        transform="matrix(.9781476 .20791169 -.20791169 .9781476 145.702842 160.000056)"
      >
        <path d="m21.9326875 3.58281124c-.3079991 1.47644588-1.2446116 2.57578071-2.1962902 3.68334954-1.3220903 1.53872113-2.8960747 2.80350202-4.6218324 3.91208292-.5044958.3238703-.9378811.7465884-1.4105363 1.116003-1.4459147 1.130847-2.911995.7165629-4.38267453.0495926-.24375708-.110993-.50944876-.1788034-.75143693-.2928327-.18184491-.0856907-.49034444-.2297455-.47973092-.3201593.02228838-.1966837.16627842-.4486952.342109-.550242 2.03744128-1.1804397-1.81314231-3.09071592.13762192-4.38754644 1.87823856-1.24858735 5.83584516-3.52768982 7.46501986-5.05763946.8076885-.75907094 6.6538526-1.77711133 5.8977505 1.84739184z" />
        <path
          d="m13.986223 3.75010359c-.2705898 1.45671007-1.2575098 2.53002306-2.2190508 3.61791641-1.3357913 1.5113864-2.92608717 2.753699-4.66972918 3.8425864-.50972396.3181169-.94760044.7333256-1.42515388 1.0961777-1.46089891 1.110758-2.94217248.7038335-4.4280929.0487116-.24628317-.1090213-.51472826-.175627-.75922419-.2876306-.18372939-.0841685-.49542595-.2256642-.48470245-.3144718.02251936-.1931897.16800159-.4407244.34565433-.5404673 1.37237041-.7729797 1.99392768-2.6337896 1.86467181-5.58242957 2.38864344-3.20118784 4.32582249-4.99238461 5.81153714-5.37359031 5.03343922-1.29148335 6.11815112 2.66410543 5.96409012 3.49319747z"
          transform="matrix(-1 0 0 1 14 0)"
        />
      </g>
      <path
        d="m116.399395 523.483551c-30.7462703-17.140047-63.0219405-32.899205-92.2388107-51.420142-12.5906228-7.981353-20.04860491-25.773933-22.1253766-37.563829-5.25199887-29.815757-1.69201498-64.731973 26.8827557-84.933163 24.7200859-17.476086 109.8434496-44.532371 147.6472686-66.006938 94.531761-53.699034 105.703702-65.996676 115.260291-79.133456 22.593487-31.057701 12.074578-83.863526 77.895402-86.650105 13.982976-.591981 21.781154-2.273926 31.813859 2.484017 18.626034 8.833271 27.36821 26.465469 24.771335 42.254412-.691776 4.205984-3.066999 8.191717-3.897665 12.384182-2.159226 10.897841-4.277634 21.830389-5.060284 32.827548-.906767 12.741157-.819981 25.5311 0 38.275414.804028 12.496363 11.644592 27.919129 23.826986 36.297339 9.539766 6.560793 32.662798 17.098115 36.290607 29.126004 7.567698 25.090472-1.974488 47.688624-26.46849 66.660455-9.947295 7.704678-12.914322 7.909852-20.842488 15.485124-9.378873 8.961406-48.812368 31.178899-55.03432 46.257558-5.39954 13.085575-22.488097 26.444604-26.460297 38.107642-5.403546 15.865707-11.822747 47.032095-4.815287 61.271345 3.273291 6.651371 6.573087 13.315446 10.646195 19.725254 1.766575 2.780042 9.79593 5.590066 6.801983 7.732769-4.03762 2.889638-11.381088.307909-16.918096-.492886-12.824361-1.854737-25.171703-5.192008-37.880854-7.432468-14.862824-2.620125-29.985797-4.416009-44.812017-7.145223-6.400569-1.178218-12.369916-3.454135-18.793429-4.56328-3.851953-.665115-7.927563-.247545-11.831085-.724641-30.69649-3.751785-61.596212-6.85051-91.107375-14.339408z"
        fill="#fce9bf"
        opacity=".108"
      />
      <path
        d="m117.417108 295.739297c-19.9210209-12.470334-40.8329656-23.935994-59.7630628-37.411002-8.1576743-5.806876-12.9898252-18.751964-14.3354002-27.329766-3.4028576-21.692615-1.0962847-47.096095 17.4177855-61.79358 16.0165556-12.71482 71.1694015-32.399767 95.6631265-48.023703 61.248703-39.0690214 68.48719-48.0162368 74.679063-57.5739725 14.638697-22.5961976 7.82332-61.01535972 50.469729-63.0427505 9.059803-.43069889 14.112373-1.65440659 20.61273 1.80726034 12.068117 6.42669354 17.732319 19.25509436 16.049761 30.74242466-.448214 3.0600866-1.98716 5.959928-2.525363 9.0101784-1.398998 7.9287831-2.77155 15.8828176-3.278642 23.8838597-.58751 9.2698974-.531279 18.5752888 0 27.8474834.520943 9.0917965 7.544726 20.3127125 15.437902 26.4083245 6.180973 4.77334 21.162771 12.439826 23.513288 21.190781 4.903238 18.254708-1.279303 34.696115-17.149375 48.499173-6.445018 5.60558-8.367404 5.754856-13.504195 11.266285-6.076729 6.519919-31.626347 22.684376-35.657654 33.654935-3.498452 9.520481-14.570412 19.239914-17.144067 27.725421-3.501048 11.543181-7.660155 34.218454-3.119905 44.578297 2.12082 4.83924 4.172734 10.500381 5.940369 13.294053 1.767635 2.793673 6.41084 4.867773 5.364596 6.68318-1.046244 1.815406-7.373996.22402-10.961516-.358603-8.309117-1.349423-16.309166-3.777473-24.543636-5.407532-9.629872-1.906286-19.428298-3.212891-29.034453-5.198545-4.147036-.857219-17.312976-3.500142-19.842134-3.847256-19.888767-2.729631-39.909211-4.984125-59.029986-10.432713z"
        fill="#fbc5a9"
        opacity=".124"
      />
      <path
        d="m143.374262 595.826356c.383503-1.010282 3.107981-6.894621 3.794634-7.715028 15.720134-18.782277 43.009385-1.482578 53.377477 16.827047 3.101475 5.477078.825651 9.494339-2.9366 13.977252-8.545111 10.181934-21.376217 19.234542-35.806081 16.632929-5.833757-1.051789-10.675564-7.360319-14.634796-12.422521-5.240266-6.70011-6.478576-20.229229-3.794634-27.299679z"
        fill="#fbc5a9"
        transform="matrix(.54463904 -.83867057 .83867057 .54463904 -431.589619 421.110804)"
      />
      <path
        d="m180.778817 133.002669c-.06334.004826-.125664.019034-.185034.042184-5.037585 2.079638-8.593783 7.101548-8.593783 12.960807 0 7.721914 6.176504 13.99434 13.774724 13.99434 4.543241 0 8.581908-2.243681 11.09172-5.694751.178776-.242032.178-.576742-.001896-.817899-.179897-.241157-.494621-.329384-.769061-.215593-1.449841.598498-3.035571.938563-4.697814.938563-6.894796 0-12.458929-5.660093-12.458929-12.644433 0-2.808162.900003-5.389343 2.425996-7.487543.155002-.213389.173323-.500294.046783-.732605-.126541-.232312-.374323-.366665-.632706-.34307zm-1.726981 2.393888c-.907273 1.864807-1.428887 3.950824-1.428887 6.16933 0 7.721907 6.176537 13.9943 13.774724 13.9943.966024 0 1.906553-.122973 2.816656-.316375-2.221917 2.091315-5.163902 3.406321-8.439605 3.406321-6.894862 0-12.458929-5.660127-12.458929-12.644473 0-4.463733 2.293923-8.361001 5.736041-10.609103z"
        fill="#fff"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default RShape;
