import React from 'react';
import { Link } from 'gatsby';

export type Crumb = {
  name: string;
  path: string;
};

type Props = {
  children: Crumb[];
};

const Breadcrumb: React.FC<Props> = ({ children }) => (
  <ul className="relative z-10 flex mw-1080x -mrt-20x mrr-auto mrb-30x mrl-auto pdr-5x pdl-5x justify-center md:mrb-15x md:justify-start">
    {children.map(({ name, path }, idx) => {
      const isLast = idx === children.length - 1;

      return (
        <li key={path} className="mrr-5x fsz-md">
          {!isLast ? (
            <span>
              <Link
                className="mrr-5x current td-none hover:td-underline"
                to={path}
              >
                {name}
              </Link>
              &gt;
            </span>
          ) : (
            <span className="fw-bold">{name}</span>
          )}
        </li>
      );
    })}
  </ul>
);

export default Breadcrumb;
