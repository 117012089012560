import React from 'react';
import cx from 'classnames';
import * as s from './looper.module.css';

type Props = React.HTMLAttributes<HTMLDivElement>;

const Looper: React.FC<Props> = ({ className, children, ...props }) => (
  <div {...props} className={cx(s.Container, className)}>
    <span className={s.Slide}>{children}</span>
    <span className={s.Slide}>{children}</span>
  </div>
);

export default Looper;
