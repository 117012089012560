import React from 'react';

type Props = React.SVGAttributes<HTMLOrSVGElement>;

const MShape: React.FC<Props> = (props) => (
  <svg
    height="301"
    viewBox="0 0 392 301"
    width="392"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m760.537248 4031.78119c32.818849-1.64491 62.607443-2.08252 91.978084 13.31596 20.60106 10.80075 32.135333 27.98223 50.440781 43.94326 7.960683 6.94115 104.885677 57.1408 98.766067 103.26147-2.890154 21.78176-24.570591 52.7247-31.842814 64.66174-20.567786 33.76115-40.288694 71.9574-84.583045 73.21631-25.448627.72329-98.874674 5.89474-116.744716-5.29451-29.564433-18.51166-42.809376-51.30776-59.135775-79.93251-5.503985-9.65002-9.151578-20.3337-15.151857-29.68361-9.138121-14.23944-29.210558-39.231-43.652798-50.99649-2.441492-1.98898-21.43325-22.14096-30.058438-29.72651-15.754985-13.85595-12.428015-27.10232.508946-55.31668 12.93696-28.21435 41.442113-42.53492 139.475565-47.44843z"
      fill="#f5b700"
      fillRule="evenodd"
      opacity=".21"
      transform="translate(-610 -4031)"
    />
  </svg>
);

export default MShape;
