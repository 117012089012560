import React from 'react';

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  address: string;
  subject?: string;
};

const Mailto: React.SFC<Props> = ({ address, subject, children, ...props }) => {
  let href = `mailto:${address}`;

  if (subject) {
    href += `?subject=${encodeURIComponent(subject)}`;
  }

  return (
    <a {...props} href={href}>
      {children}
    </a>
  );
};

export default Mailto;
