type WindowWithGtag = Window & {
  gtag?: typeof gtag;
};

type CartEvent = {
  action: 'cart_add' | 'cart_update' | 'cart_remove';
  category: 'cart';
  label: string;
};

type InstagramEvent = {
  action: 'instagram_click';
  category: 'instagram';
  label: string;
};

type AnalyticsEvent = CartEvent | InstagramEvent;

const track = (event: AnalyticsEvent) => {
  if (typeof window === 'undefined') {
    return;
  }

  const command = 'event';
  const action = event.action;
  const payload = {
    event_category: event.category,
    event_label: event.label,
  };

  if (process.env.NODE_ENV !== 'production') {
    return console.log(command, action, payload);
  }

  const ref = window as WindowWithGtag;
  if (ref.gtag) {
    ref.gtag(command, action, payload);
  }
};

export default track;
